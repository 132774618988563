// 判断是否是关联订单号，U开头为关联订单号，G开头为老订单号
exports.isRelationBillno = billno => {
  if (typeof billno !== 'string') return false
  return typeof billno.startsWith === 'function' ? billno.startsWith('U') : billno.indexOf && billno.indexOf('U') === 0
}

// 将数组转化为可存储为kv对的map对象
// * 浅拷贝
exports.generateKVMapFromArr = (source, key) => {
  const map = Object.create(null)
  if (!Array.isArray(source) || !source.length) {
    return map
  }
  if (typeof key !== 'string' || !key) {
    return map
  }

  source.forEach(current => {
    if (!current) {
      return
    }
    const KEY = current[key]
    if (!KEY) {
      return
    }
    map[KEY] = current
  })

  return map
}

// 特殊log
exports.generateConsole = (title, content = '') => {
  let str = ''
  if (content && `${content}`.length > 0 && `${content}`.length <= 225) {
    for (let i = 0; i < `${content}`.length; i++) {
      str += ' '
    }
  }
  str += content
  console.log(`%c🚀 ${title} 🚀 %s `, 'font-family:sans-serif;font-size:1.2em; color:white; font-weight:bold; background: linear-gradient(#17a6ea 26%, #4FCA2D); border-radius: 4px; padding: 4px', str)
}
