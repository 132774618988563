const { appLanguage, language } = gbCommonInfo
let cbTipInstance = null

const log = ({ method, url, config = {} } = {}) => (
  console.log(`%c [USE AJAX] Method: ${method || config.method} URL: ${url || config.url} 请尽快调整 %c`, 'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px; color: #fff',
  `background:{ request: '#dadada'}; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff`)
)

function addQuery(url) {
  if(url.indexOf('?') > -1) {
    url += `&_ver=1.1.8&_lang=${appLanguage}`
  } else {
    url += `?_ver=1.1.8&_lang=${appLanguage}`
  }
  return url
}
$.ajaxSettings.beforeSend = function (jqXHR, setting) {

  if (gbCommonInfo.NODE_SERVER_ENV !== 'production') {
    log({
      method: setting.type,
      url: setting.url
    })
  }

  // 添加多语言参数
  setting.url = addQuery(setting.url)
  /* header里加请求头参数 */
  jqXHR.setRequestHeader('x-csrf-token', gbCommonInfo.csrf_token)


  if( ['debug', 'localhost'].includes(gbCommonInfo.NODE_SERVER_ENV) ){
    const { bffUserTradeSer, bffMallSer } = gbCommonInfo
    jqXHR.setRequestHeader('bff-usertrade', bffUserTradeSer)
    jqXHR.setRequestHeader('bff-mall', bffMallSer)
  }

    
  // jquery 请求拦截
  const originSuccess = setting.success
  setting.success = function (response) {
    // 当前请求 URL
    const url = setting.url

    // 熔断需要提示 “稍后再试” 的 API 列表
    // https://doc.weixin.qq.com/txdoc/excel?docid=e2_AEsAzwZpACscvSMWl7wQg6l5QhgQj&scode=APMA2QcuAAkw2w1FtDAEsAzwZpACs&type=1&tab=BB08J2&c=C2A0A0
    const circuitBreakerTipApi = [
      // 收藏夹相关接口
      '/user/wishlist'
    ]
      
    // 黑名单规则
    const circuitBreakerTipBlackListApi = []

    // 检查当前请求是否匹配熔断提示
    if (circuitBreakerTipApi.some((i) => url.indexOf(i) > -1) && !(circuitBreakerTipBlackListApi.some((fn) => fn(url)))) {
      // 请求以 { code: x, info: x } 的形式返回
      if (typeof response.code !== 'undefined') {
        if (Number(response.code) === -4 || Number(response.code) === -2) {
          if (!cbTipInstance || (cbTipInstance && cbTipInstance.fadeOut)) {
            cbTipInstance = window._gb_app_?.$toast(language.SHEIN_KEY_PWA_17639)
          }

          response.code = -1 // 让请求走正常错误处理
          response.originCode = -4 // 原状态码，界面可能需要拿原状态码进行二次判断
        }
      }
        
      // 请求以 { data: { code: x, info: x } } 的形式返回
      if (typeof response.data !== 'undefined' && typeof response.data.code !== 'undefined') {
        if (Number(response.data.code) === -4 || Number(response.data.code) === -2) {
          if (!cbTipInstance || (cbTipInstance && cbTipInstance.fadeOut)) {
            cbTipInstance = window._gb_app_?.$toast(language.SHEIN_KEY_PWA_17639)
          }

          response.data.code = -1 // 让请求走正常错误处理
          response.data.originCode = -4 // 原状态码，界面可能需要拿原状态码进行二次判断
        }
      }
    }

    originSuccess.apply(this, arguments)
  }
}
