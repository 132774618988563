//货币和多语言纠正

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { setLocalStorage } from '@shein/common-function'
import schttp from '../../services/schttp'

const { lang, SUPPORT_LANGS, currency, currencies={} } = gbCommonInfo
const MAX_AGE = 1000 * 60 * 60 * 24 * 20  //20天

const currencyManager = UserInfoManager.get({ key: 'currency' })
const langManager = UserInfoManager.get({ key: 'language' })

let nowCurrencies = []

try {
  const nowCurInfo = currencies[lang] || {}
  nowCurrencies = Object.keys(nowCurInfo)

  //提供给用增游戏等其它pwa项目获取货币
  setLocalStorage({ key: 'currency', value: currencyManager, expire: MAX_AGE })
} catch(e){ 
  console.log('currency error', e)
  nowCurrencies = []
}

let currencyDisMatch = false
let langDisMatch = false

//如果货币不匹配 
if( currency != currencyManager ){
  //gbCommonInfo.currency = currencyManager
  currencyDisMatch = true
}

//如果多语言不匹配 
if( langManager != lang ){
  //gbCommonInfo.lang = langManager
  langDisMatch = false
}


if( langDisMatch || currencyDisMatch ){
  let data = {
    SUPPORT_LANGS, nowCurrencies,
    lang, currency, currencyManager, langManager
  }

  langDisMatch && (data.langDisMatch = 1)
  currencyDisMatch && (data.currencyDisMatch = 1)
  nowCurrencies.includes(currency) && (data.currencyInNowCurrencies = 1)

  schttp({
    method: 'POST',
    url: '/api/common/infoDismatchLog/post',
    data,
  })
}
